@import "./variables";

html {
  scrollbar-color: $color-accent #f1eeee;
  scrollbar-width: thin;
  min-width: 375px; // to make the site proportionally smaller when the width is less than 375 pixels
}

body {
  font-family: Raleway, Arial, sans-serif;
  font-feature-settings: 'lnum';
  font-size: 18px;

  /**
    * width: 100%; Fixes issue on phone, ios Safari,
    * when menu opened on phone - page content will jump, but shoudn`t.
    * Before opening the mobile menu, the width of the body element was,
    * for example, 660 pixels, but after opening the menu it became 550
    * for some indirect reasons.
    */
  width: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  }

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-accent;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  }

  @media (max-width: $mobileTablet) {
    font-size: 16px;
  }
}

html,
body {
  /*
  * Because pages has bubbles absolute positioning outside,
  * which cause scrollX
  */
  // Disabled 2024-may-20
  // Should be removed after 2024-09-01
  // overflow-x: hidden;
}

.page-border {
  position: relative;
}


.app-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  min-height: calc(100vh - 700px);

  @media (width <= 2000px) and (height <= 1700px) {
    min-height: 100vh;
  }
}

p {
  line-height: 1.5;

  @media (max-width: $smallDesk) {
    font-size: 16px;
  }
}

.container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: $mobileTablet) {
    max-width: 540px;
  }

  @media (min-width: $iPadMini) {
    max-width: 720px;
  }

  @media (min-width: $smallDesk) {
    max-width: 960px;
  }

  @media (min-width: $mDesk) {
    max-width: 1200px;
  }

  @media (min-width: $mediumDesk) {
    max-width: 1240px;
  }

  @media (min-width: $desk) {
    max-width: 1340px;
  }

  @media (min-width: $largeDesk) {
    max-width: 1540px;
  }
}

.desktop {
  display: block;

  @media (max-width: $smallDesk) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: $smallDesk) {
    display: block;
    width: 100%;
  }
}
