@import '@/style/variables.scss';

.header {
  background: transparent;
  padding: 25px 0;
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  z-index: 10;

  @media (max-width: $mDesk) {
    padding: 15px 0;
  }

  &--fixed {
    position: fixed;
    transform: translateZ(0);
  }

  &--background {
    background: url('@/assets/icons/header-background.svg') center no-repeat;
    background-size: cover;
  }

  &--animated {
    animation: animateHeader 0.7s;

    @keyframes animateHeader {
      0% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(0);
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo svg {
    max-width: 300px;
    height: auto;
    display: block; // this is needed to remove white space below the logo
    @media (max-width: $iPadMini) {
      max-width: 250px;
    }
  }
}

.nav {
  color: $white;

  @media (max-width: $mDesk) {
    display: none;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    position: relative;
    & + .nav__item {
      margin-left: 70px;

      @media (max-width: $desk) {
        margin-left: 35px;
      }
    }
  }

  &__link {
    position: relative;
    padding-bottom: 20px;
    cursor: pointer;

    &:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 10px;
      right: 0;
      background-color: $orange;
      border-radius: 3px;
      transition: width 0.4s, left 0.4s;
    }
    &:hover {
      &:before {
        width: 100%;
        left: 0;
      }
    }
  }

  &__submenu {
    position: absolute;
    top: 160%;
    left: 0;
    width: 300px;
    padding: 30px 15px 30px 30px;
    background: radial-gradient(50% 50% at 50% 50%, #52256a 0%, #38193d 100%);
    border-radius: 10px;
    animation: animateMount 0.4s forwards;
  }

  &__submenu-item + &__submenu-item {
    margin-top: 15px;
  }

  &__submenu-link {
    transition: color 0.4s;
    &:hover {
      color: $orange;
    }
  }

  &__btns {
    position: absolute;
    top: 100%;
    right: 40px;
    width: 800px;
    padding-top: 35px;
    animation: animateMount 0.4s forwards;
  }

  @keyframes animateMount {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }

    100% {
      opacity: 0.95;
      transform: translateY(0);
    }
  }

  &__btns-inner {
    display: flex;
    justify-content: space-between;
    padding: 55px 0;
    background: radial-gradient(
        26.3% 56.93% at 97.8% 91.31%,
        rgba(249, 110, 32, 0.5) 0%,
        rgba(172, 89, 255, 0) 100%
      ),
      radial-gradient(
        26.74% 57.87% at 6.1% 24.14%,
        rgba(172, 89, 255, 0.5) 0%,
        rgba(172, 89, 255, 0) 100%
      ),
      #360d4b;
    border-radius: 10px;
    position: relative;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 25px 15px;
      border-color: transparent transparent #360d4b transparent;
      position: absolute;
      right: 40px;
      bottom: 100%;
    }
  }

  &__btns-item {
    padding-left: 55px;

    &:first-child {
      padding-right: 25px;
      position: relative;

      &::after {
        content: '';
        width: 1px;
        height: 120%;
        background-color: $color-accent;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .nav__btn {
      min-width: 280px;
    }
  }

  &__btns-text {
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    text-align: center;
    padding: 15px 30px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    background: transparent;
    z-index: 0;
    cursor: pointer;
  }
}
