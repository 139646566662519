@mixin font-style($font-size: null, $font-color: null, $font-weight: null, $letter-spacing: null) {
  @if $font-size {
    font-size: $font-size;
  }

  @if $font-color {
    color: $font-color;
  }

  @if $font-weight {
    font-weight: $font-weight;
  }

  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }
}

@mixin flex(
  $display: null,
  $justify-content: null,
  $align-items: null,
  $flex-direction: null,
  $gap: null,
  $flex-wrap: null
) {
  @if $display {
    display: $display;
  }

  @if $justify-content {
    justify-content: $justify-content;
  }

  @if $align-items {
    align-items: $align-items;
  }

  @if $flex-direction {
    flex-direction: $flex-direction;
  }

  @if $gap {
    gap: $gap;
  }

  @if $flex-wrap {
    flex-wrap: $flex-wrap;
  }
}
