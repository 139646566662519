@import '@/style/variables.scss';

.meeting-modal {
  .modal__content {
    max-width: 1000px;
    padding-bottom: 20px;

    @media (max-width: 692px) {
      overflow: hidden;
    }
  }

  &__inner {
    min-height: 690px;
  }

  &__calendar {
    width: 100%;
    border: none;
    min-height: 690px;
  }
}
