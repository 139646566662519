@import '@/style/variables.scss';

.mobile-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  // width: 100%;
  width: 0;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 500ms;
  overscroll-behavior: contain;
  scrollbar-width: none;
  background-color: white;

  &::-webkit-scrollbar {
    display: none;
  }

  padding-bottom: 90px;

  &_menuOpened {
    width: 100%;
  }
}

.mobile-menu {
  background-color: #fff;
  width: 100vw;
  // min-height: 100%;
  // height: 100vh;
  // padding-top: 60px;
  // transform: translateZ(0);
  // transform: translateX(100%);

  &-enter {
    // transform: translateX(100%);

    .mobile-menu__logo {
      opacity: 0;
    }
  }
  &-enter-active {
    // transition: transform 0.5s;
    // transform: translateX(0);

    .mobile-menu__logo {
      transition: opacity 1s;
      opacity: 1;
    }
  }
  &-enter-done {
    // transform: translateX(0);

    .mobile-menu__logoContainer {
      opacity: 1;
    }
  }
  &-exit {
    // transform: translateX(0);
    .mobile-menu__logoContainer {
      opacity: 1;
    }
  }
  &-exit-active {
    // transition: transform 0.5s;
    // transform: translateX(100%);
    .mobile-menu__logoContainer {
      transition: opacity 0.5s;
      opacity: 0;
    }
  }

  .mobile-menu__logoContainer {
    // opacity: 1;
    z-index: 1;
    position: sticky;
    top: 0;
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-items: flex-start;
    background:
            linear-gradient(to top,
                    rgba(255, 255, 255, 0) 0,
                    rgba(255, 255, 255, 0.7) 5px,
                    rgba(255, 255, 255, 0.94) 8px,
                    rgba(255, 255, 255, 1) 100%
            );
    box-shadow: none; // Fix articats issue on safary when sticky in fixed container
  }

  .mobile-menu__logoLink {
    width: 250px;
    display: block;

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }

  /* &--active {
    transform: translateX(0);

    .mobile-menu__logo {
      opacity: 1;
    }
  } */

  &__inner {
    // padding-bottom: 90px;
    // overflow-y: scroll;
    // height: 100%;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
      // display: none;
    // }
  }

  &__logo {
    // position: absolute;
    // top: -50px;
    // left: 15px;
    opacity: 0;
    transition: opacity 0.4s 0.5s;

    svg {
      max-width: 300px;
      height: auto;
      display: block;

      path:first-child {
        fill: $black;
      }
    }

    @media (max-width: $iPadMini) {
      // top: -45px;
      svg {
        max-width: 250px;
      }
    }
  }

  &__nav {
    margin-bottom: 40px;
  }

  &__item {
    font-size: 20px;
    line-height: 1.5;
    border-bottom: 3px dashed #f0e1ff;
  }

  &__link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 20px 0;
    background: none;
    border: none;
    &--extra {
      color: $color-accent;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__footer-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    svg {
      margin-bottom: 10px;
    }
  }

  &__footer-social {
    display: flex;
    align-items: center;
    .footer-link {
      width: 35px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $black;

      & + .footer-link {
        margin-left: 10px;
      }
      path {
        fill: #000;
      }
      circle {
        stroke: #000;
      }
    }
  }
}

.mobile-submenu {
  &__title {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__list {
    overflow: hidden;
    transition: max-height 0.5s;
  }

  &__link {
    position: relative;
    padding-left: 40px;

    &::before {
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: $black;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__arrow {
    svg {
      transition: transform 0.5s;
    }
    &--active svg {
      transform: rotate(-180deg);
    }
  }
}
