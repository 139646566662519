@import '@/style/mixins.scss';
@import '@/style/variables.scss';

.footer {
  padding: 90px 0 60px;
  background-color: $black;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  flex: 0 0 auto;
  margin-top: auto;

  @media (max-width: $iPadMini) {
    padding: 40px 0;
  }

  &__inner {
    position: relative;

    &::before {
      content: '';
      width: 1000px;
      height: 1000px;
      background: radial-gradient(50% 50% at 50% 50%, #f96e20 0%, rgba(249, 110, 32, 0) 100%);
      opacity: 0.3;
      position: absolute;
      left: -330px;
      top: -240px;
      z-index: -1;
    }

    &::after {
      content: '';
      width: 900px;
      height: 845px;
      background: radial-gradient(50% 50% at 50% 50%, #ac59ff 0%, rgba(165, 74, 255, 0) 100%);
      opacity: 0.3;
      position: absolute;
      right: -410px;
      top: -250px;
      z-index: -1;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: $mDesk) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__logo svg {
    @media (max-width: $iPadMini) {
      width: 320px;
    }
  }

  &__phone,
  &__address {
    display: flex;
    align-items: center;
    transition: color 0.4s;

    svg {
      margin-right: 20px;
      flex-shrink: 0;
    }

    &:hover {
      color: $orange;
    }

    @media (max-width: $mDesk) {
      flex-direction: column;
      align-items: center;

      svg {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  &__phone {
    font-size: 30px;

    @media (max-width: $mDesk) {
      font-size: 20px;
    }


  }

  &__address {
    @media (max-width: $desk) {
      max-width: 390px;
    }

    @media (max-width: $iPadMini) {
      max-width: 280px;
      line-height: 30px;
    }
  }

  &__title {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 60px 0;
    border-bottom: 1px solid #fff;

    @media (max-width: $mDesk) {
      justify-content: center;
      padding: 35px 0;
    }
  }

  &__contact {
    max-width: 790px;
  }

  &__contact-info {
    @media (max-width: $mDesk) {
      display: none;
    }
  }

  &__social {
    display: flex;
  }

  &__social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #fff;
    transition: transform 0.4s;

    &:hover {
      transform: translateY(-5px);
    }

    & + .footer__social-link {
      margin-left: 35px;
    }

    path {
      fill: #fff;
    }
  }

  &__menu {
    @media (max-width: $mDesk) {
      display: none;
    }
  }

  &__menu-item + .footer__menu-item {
    margin-top: 10px;
  }

  &__menu-link {
    transition: color 0.4s;

    &:hover {
      color: $orange;
    }
  }

  &__copy {
    margin-top: 60px;
    text-align: center;

    @media (max-width: $iPadMini) {
      margin: 35px auto 0;
      max-width: 250px;
    }
  }

  &__animation {
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -70px;
    left: 0;
    z-index: -1;

    @media (max-width: $iPadMini) {
      display: none;
    }
  }

  &__animation-wave {
    background: url('@/assets/icons/wave.svg') repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: flowHorizontal 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(2) {
      top: -175px;
      animation: flowHorizontal 5s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
        flowVertical 5s ease -1.25s infinite;
      opacity: 1;
    }
  }

  @keyframes flowHorizontal {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  @keyframes flowVertical {
    0% {
      transform: translateY(-25px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(-25px);
    }
  }
}
