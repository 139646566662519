@import '@/style/variables.scss';

.textarea {
  padding: 18px 30px;
  border: 1px solid #dec4f9;
  border-radius: 10px;
  width: 100%;
  min-height: 179px;
  resize: none;
  transition: border-color 0.4s;
  outline: none;

  &::placeholder {
    color: #140612;
  }

  &:hover,
  &:focus {
    border-color: darken($color: #dec4f9, $amount: 20%);
  }

  @media (max-width: $iPadMini) {
    padding: 10px 20px;
    min-height: 100px;
  }
}
