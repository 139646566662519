@import '@/style/variables.scss';

.modal-wrapper {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
}

.modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  // background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10;
  overflow: auto;

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 3px solid $black;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 54px;
    height: 54px;
    transition: background 0.4s, border-color 0.4s;

    svg {
      will-change: transform;
      transition: transform 0.4s;
    }

    path {
      transition: fill 0.4s;
    }

    &:hover {
      background: $color-accent;
      border-color: $color-accent;

      svg {
        transform: rotate(90deg);
      }

      path {
        fill: $white;
      }
    }
  }

  &__content {
    max-height: 100%;
    max-width: 1165px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    position: relative;
    padding: 65px;
    border: 1px solid #dec4f9;
    border-radius: 10px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transform: translateZ(0); // Fixes artifacts in render and transitions
    overscroll-behavior: contain;

    @media (max-width: $mDesk) {
      padding: 70px 20px 30px;
      /*
      / rounded edges look ugly when
      / the modal is the entire width of the screen
      */
      border-radius: 0;
    }

    @media (max-width: $iPadMini) {
      // to prevent horizontal scroll on mobile
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .modal-wrapper-enter & {
      transform: translateY(30px);
    }

    .modal-wrapper-enter-active & {
      transform: translateY(0);
      transition: transform 0.4s;
    }

    .modal-wrapper-exit & {
      opacity: 1;
    }

    .modal-wrapper-exit-active & {
      transition: opacity 0.3s;
      opacity: 0;
    }
  }
}
