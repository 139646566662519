@import '@/style/variables.scss';

.input-wrapper {
  position: relative;
  width: 100%;

  &--error {
    .input {
      border-color: #e4234c;
    }
  }
}

.input {
  padding: 20px 30px;
  border: 1px solid #dec4f9;
  border-radius: 10px;
  width: 100%;
  transition: border-color 0.4s;
  outline: none;

  &::placeholder {
    color: $black;
  }

  &:hover,
  &:focus {
    border-color: darken($color: #dec4f9, $amount: 20%);
  }

  &__error {
    position: absolute;
    bottom: -18px;
    left: 10px;
    color: #e4234c;
    font-size: 14px;
    line-height: 1;
  }

  @media (max-width: $iPadMini) {
    padding: 10px 20px;
  }
}
