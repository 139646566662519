@import './variables';

[img-placeholder], [data-img-placeholder] {
  box-shadow: 0 0 10px 5px inset #F8F8FF;
}

.section-title {
  margin-bottom: 60px;
  color: $color-secondary;
  font-size: 48px;
  font-weight: 700;
  line-height: 68px;

  @media (max-width: $mDesk) {
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 40px;
  }

  &__content {
    margin-bottom: 10px;
  }
}

.section-padding {
  padding: 100px 0;

  &.container {
    padding: 100px 15px;

    & + .section-padding {
      padding: 0 15px 100px;
    }
  }

  & + .section-padding {
    padding: 0 0 100px;
  }

  @media (max-width: $mDesk) {
    padding: 40px 0;

    & + .section-padding {
      padding: 0 0 40px;
    }

    &.container {
      padding: 40px 15px;

      & + .section-padding {
        padding: 0 15px 40px;
      }
    }
  }
}

.three-column-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: $mDesk) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $iPadMini) {
    grid-template-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.bg-gray {
  background: linear-gradient(180deg, #f9f3ff 0%, rgb(242 230 255 / 0%) 100%);
}

.relative {
  position: relative;
}

.industry-secondary-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: $smallDesk) {
    display: none;
  }
}

.hash-filter-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f0f0f0;
  color: $color-secondary;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  transition: background-color 0.4s, text-shadow 0.4s, transform 0.5s;

  &:hover {
    background-color: $divider;
    text-shadow: 0 0 1px $color-secondary;
    transform: translateY(-13%);
  }
}
