@import '@/style/mixins.scss';
@import '@/style/variables.scss';

.contactus {
  padding-bottom: 50px;

  @media (max-width: $mDesk) {
    padding-bottom: 0;
  }

  z-index: 0; // To reset z-index inner containers
  position: relative; // Due to modalBackground absolute

  &__modalBackground {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // overflow: hidden; // TODO Remove this after 2024-08-01

    &::before {
      content: '';
      display: block;
      width: 912px;
      height: 684px;
      background: radial-gradient(50% 50% at 50% 50%, #f96e20 0, rgba(249, 110, 32, 0) 100%);
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 40%;
      transform: translate(-35%, -45%);
      pointer-events: none;
    }
  }

  &__form {
    @include flex(flex, flex-start, space-between, column);
    max-width: 995px;
    margin: 0 auto;
    position: relative;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media (max-width: $iPadMini) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__input {
    height: 67px;
    margin-bottom: 30px;

    @media (max-width: $iPadMini) {
      height: 50px;
      font-size: 16px;
    }
  }

  &__textarea {
    margin-top: 13px;
    margin-bottom: 50px;

    @media (max-width: $iPadMini) {
      margin-bottom: 30px;
      font-size: 16px;
    }
  }

  &__select {
    height: 67px;
    padding: 18px 30px;
    border: 1px solid #dec4f9;
    border-radius: 10px;
    margin-bottom: 17px;
    width: 100%;
    transition: border-color 0.4s;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 63%;
    background-size: 40px;

    &:hover,
    &:focus {
      border-color: darken($color: #dec4f9, $amount: 20%);
    }

    @media (max-width: $iPadMini) {
      height: 50px;
      font-size: 16px;
      padding: 10px 45px 10px 15px;
      background-position-y: 92%;
      background-position-x: 100%;
    }
  }

  &__btn {
    margin: 0 auto;
  }

  & > svg {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0px 25px;

    @media (max-width: $mDesk) {
      display: none;
    }
  }

  .section-title {
    text-align: center;
  }
}
