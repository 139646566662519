// colors
$white: #fff;
$color-accent: #a54aff;
$color-secondary: #3f1696;
$light-grey: #dbdbdb;
$orange: #f96e20;
$white: #fff;
$black: #140612;
$scroll-dark: #17090c;
$divider: #f0e1ff;
$warning: #de2d20;


// fonts
$baseFont: 'Raleway', arial, sans-serif;
$font-size-btn: 16px;

// media dimensions
$mMobile: 430px;
$mobileTablet: 576px;
$iPadMini: 768px;
$smallDesk: 992px;
$tabletPro: 1024px;
$mDesk: 1200px;
$mediumDesk: 1365px;
$desk: 1450px;
$largeDesk: 1650px;
$extraLargeDesk: 2500px;
