@import '@/style/variables.scss';

.burger {
  width: 36px;
  height: 26px;
  background: none;
  border: none;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 8;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  @media (max-width: $mDesk) {
    display: flex;
  }

  &--active {
    .burger__line {
      background-color: #000;
    }

    .burger__line--1 {
      transform: translateY(12px) rotate(405deg);
    }

    .burger__line--2 {
      opacity: 0;
    }

    .burger__line--3 {
      transform: translateY(-12px) rotate(-405deg);
    }
  }
}

.burger__line {
  height: 2px;
  border-radius: 3px;
  background-color: #fff;
  width: 100%;
  will-change: transform;
  transition: all 0.8s;
  &--3 {
    margin-left: auto;
  }
}
