@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-width: 320px;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-size: 1em;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  cursor: pointer;
  transition: all 0.4s;
}

input,
button,
textarea,
select {
  color: inherit;
  font: inherit;
}
