@import '@/style/variables.scss';

.btn {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.8;
  padding: 15px 30px;
  background: none;
  border: 1px solid $white;
  border-radius: 10px;
  color: $white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 310px;

  svg {
    margin-left: 10px;
    transition: transform 0.4s;
  }

  &:hover {
    svg {
      transform: translateX(15px);
    }
  }

  &--gradient {
    &::before {
      content: '';
      position: absolute;
      width: 105%;
      height: 200%;
      background: radial-gradient(50% 50% at 50% 50%, $color-accent 0, rgba(165, 74, 255, 0) 100%);
      opacity: 0.3;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: height 0.4s, opacity 0.4s;
      z-index: -1;
      pointer-events: none;
    }

    &:hover {
      &::before {
        height: 140%;
        opacity: 0.8;
      }
    }
  }

  &--colored {
    border-color: $color-secondary;
    color: $color-secondary;
    background-color: $white;

    path {
      stroke: $color-secondary;
    }
  }

  @media (max-width: $mobileTablet) {
    min-width: auto;
    width: 100%;
  }
}
